import React from "react";
import { Link } from "react-router-dom";

import Facebook from "../../assets/svg/Facebook";
import Instagram from "../../assets/svg/Instagram";
import LocationIcon from "../../assets/svg/LocationIcon";
import MessageIcon from "../../assets/svg/MessageIcon";
import Twitter from "../../assets/svg/Twitter";
import Linkedin from "../../assets/svg/Linkedin";
import apple from "../../assets/images/AppStore.png";
import play from "../../assets/images/GooglePlay.png";
import {
	CONTACT_EMAIL,
	FACEBOOK_URL,
	TWITTER_URL,
	INSTAGRAM_URL,
	APPLE_STORE_URL,
	GOOGLE_PLAY_URL,
	LINKEDIN_URL,
} from "../../config";
import { useTranslation } from "react-i18next";
import LogoEn from "../../assets/svg/LogoEn";
import LogoAr from "../../assets/svg/LogoAr";

const Footer = () => {
	const { i18n } = useTranslation();
	const { t } = useTranslation();
	return (
		<div className="bg-[#252B42]">
			<div className="container mx-auto py-14 grid grid-cols-1 md:grid-cols-3 px-4 md:px-0 md:pr-2 ">
				{/* FIRST COLUMN START */}
				<div className="mx-0 md:mx-[5%] lg:mx-[5%] xl:mx-[0%]">
					<a href="/">{i18n.language === "ar" ? <LogoAr /> : <LogoEn />}</a>
					<p className="text-sm text-left py-4 text-[#8E9AA6]">
						{t("Redefining local employment, one connection at a time.")}
					</p>

					<div className="flex gap-5 py-10">
						{FACEBOOK_URL && (
							<a href={FACEBOOK_URL} target="_blank" rel="noreferrer">
								<Facebook />
							</a>
						)}
						{INSTAGRAM_URL && (
							<a href={INSTAGRAM_URL} target="_blank" rel="noreferrer">
								<Instagram />
							</a>
						)}
						{TWITTER_URL && (
							<a href={TWITTER_URL} target="_blank" rel="noreferrer">
								<Twitter color="white" />
							</a>
						)}
						{LINKEDIN_URL && (
							<a href={LINKEDIN_URL} target="_blank" rel="noreferrer">
								<Linkedin color="white" />
							</a>
						)}
					</div>
				</div>

				{/* SECOND COLUMN START */}

				<div className="grid place-items-start md:place-items-center">
					<div>
						<div className="flex gap-5 my-5">
							<MessageIcon />
							<p className="text-[#8E9AA6] text-sm">
								<a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a>
							</p>
						</div>
						<div className="flex gap-5 my-5">
							<LocationIcon />
							<p className="text-[#8E9AA6] text-sm ">{t("Riyadh, Saudi Arabia")}</p>
						</div>
					</div>
				</div>

				{/* THIRD COLUMN START */}

				<div className="">
					<div className="flex justify-center xl:justify-start  gap-2 my-7">
						<a href={APPLE_STORE_URL} target="_blank" rel="noreferrer">
							<img src={apple} alt="app_store_logo" />
						</a>
						<a href={GOOGLE_PLAY_URL} target="_blank" rel="noreferrer">
							<img src={play} alt="play_store_logo" />
						</a>
					</div>
				</div>
			</div>

			{/* FOOTER COPYRIGHT AREA */}

			<div className="container mx-auto pb-10 grid grid-cols-1 md:grid-cols-2 px-0">
				<p className="text-[#8E9AA6] text-center md:text-start">{t("© 2023 Copyright reserved by OPUS")}</p>

				<div className="flex justify-center md:justify-end gap-3">
					<div className="">
						<Link to="/terms-and-condition" className="text-[#8E9AA6] underline">
							{t("Terms and condition")}
						</Link>
					</div>

					<div className="">
						<Link to="/privacy-policy" className="text-[#8E9AA6] underline">
							{t("Privacy Policy")}
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
