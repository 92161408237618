import { useState } from "react";
import { useTranslation } from "react-i18next";
import BlogEntry from "../molecules/BlogEntry";

const BlogEntriesSection = ({ posts, showButton }) => {
	const { t } = useTranslation();
	const [showMore, setShowMore] = useState(3);

	return (
		<>
			<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
				{posts.slice(0, showMore).map((post) => (
					<BlogEntry key={post.id} {...post} />
				))}
			</div>
			{showButton && showMore < posts.length && (
				<div className="flex justify-center py-12">
					<button
						className="bg-blue-500 text-white rounded-lg font-medium text-sm leading-[18px] max-w-[149px] text-center px-8 py-3.5"
						onClick={() => setShowMore(showMore + 3)}
					>
						{showMore ? t("Show less") : t("Show more")}
					</button>
				</div>
			)}
		</>
	);
};

export default BlogEntriesSection;
