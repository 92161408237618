import React from "react";
import BlogHero from "../../components/molecules/BlogHero";
import p1image from "../../assets/images/posts/Hero.png";

const BlogEntry = () => {
	return (
		<>
			<BlogHero heroImage={p1image} />
			<div className="content">
				<h1>Main Header</h1>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam non urna vitae odio ultricies tincidunt.</p>

				<h2>Subheader 1</h2>
				<p>
					Curabitur vel libero at mauris fermentum euismod. Integer nec odio. Praesent libero. Sed cursus ante dapibus
					diam.
				</p>

				<h3>Subheader 2</h3>
				<ul>
					<li>First item in a list</li>
					<li>Second item in a list</li>
					<li>Third item in a list</li>
				</ul>

				<h4>Subheader 3</h4>
				<p>Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum. Praesent mauris.</p>

				<h5>Subheader 4</h5>
				<ol>
					<li>First ordered item</li>
					<li>Second ordered item</li>
					<li>Third ordered item</li>
				</ol>

				<h6>Subheader 5</h6>
				<p>Fusce nec tellus sed augue semper porta. Mauris massa. Vestibulum lacinia arcu eget nulla.</p>
			</div>
		</>
	);
};

export default BlogEntry;
