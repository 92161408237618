import { useTranslation } from "react-i18next";
const BlogEntry = ({ title, title_ar, summary, summary_ar, id, image }) => {
	const { i18n } = useTranslation();
	const selectedLanguage = i18n.language;
	return (
		<div className="flex flex-col items-center py-12">
			<a href={`/blog/${id}`}>
				<div className="w-full mb-6">
					<img src={image} alt={selectedLanguage === "en" ? title : title_ar} className="rounded-lg w-full" />
				</div>
				<div className="w-full text-center">
					<h2 className="text-3xl font-bold mb-4">{selectedLanguage === "en" ? title : title_ar}</h2>
					<p className="text-lg">{selectedLanguage === "en" ? summary : summary_ar}</p>
				</div>
			</a>
		</div>
	);
};

export default BlogEntry;
