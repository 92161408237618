import React from "react";

const BlogHero = ({ heroImage }) => {
  return (
    <div className="bg-snowWhite py-10 md:py-0">
      <div className="container px-4 mx-auto grid grid-cols-1 md:grid-cols-2 items-center">
        <div className="text-start md:pl-24 rtl:md:pr-24"></div>
      </div>

      <div className="w-full">
        <img src={heroImage} alt="hero_banner" className="w-full" />
      </div>
    </div>
  );
};

export default BlogHero;
