import { API_URL } from "../config";
import { useState, useEffect } from "react";

const BLOG_API_URL = `${API_URL}cms/blog/`;

export const useBlogEntries = () => {
	const [posts, setPosts] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		const fetchBlogEntries = async () => {
			try {
				const response = await fetch(BLOG_API_URL);
				if (!response.ok) {
					throw new Error("Network response was not ok");
				}
				const data = await response.json();
				setPosts(data["results"]);
			} catch (error) {
				console.error("Error fetching blog entries:", error);
				setError(error);
			} finally {
				setLoading(false);
			}
		};

		fetchBlogEntries();
	}, []);

	return { posts, loading, error };
};
