import { WEBAPP_URL } from "../config";

export const headerRoutes = [
	{ id: 1, name: "Download", name_ar: "تحميل", path: "/#download" },
	{ id: 2, name: "Gallery", name_ar: "صالة عرض", path: "/#gallery" },
	{ id: 3, name: "About Us", name_ar: "معلومات عنا", path: "/#about-us" },
	{ id: 4, name: "Contact Us", name_ar: "اتصل بنا", path: "/#contact-us" },
	{ id: 5, name: "Blog", name_ar: "مدونة", path: "/blog" },
];

export const authRoutes = [
	{ id: 1, name: "Login", name_ar: "تسجيل الدخول", path: WEBAPP_URL },
	{ id: 1, name: "Register", name_ar: "تسجيل", path: `${WEBAPP_URL}signup` },
];
