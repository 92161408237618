import { useTranslation } from "react-i18next";
import BlogEntriesSection from "../../components/organizations/BlogEntriesSection";
import blog from "../../assets/images/blog.png";
import { useBlogEntries } from "../../services/blog-service";

const Blog = () => {
	const { t } = useTranslation();
	const { posts } = useBlogEntries();

	return (
		<>
			<div className="container mx-auto flex flex-col justify-start align-start">
				<div className="py-12">
					<div className="">
						<h1 className="text-5xl text-bold text-left">{t("Latest topics")}</h1>
					</div>
				</div>
				<BlogEntriesSection posts={posts} showButton={true} />
			</div>

			<div
				className="w-full bg-cover bg-center py-24 flex items-center justify-center"
				style={{ backgroundImage: `url(${blog})`, height: "50vh" }}
			>
				<div className="container mx-auto text-center w-1/4">
					<h2 className="text-4xl text-white font-bold">{t("New Opportunities Await – Join Our Team!")}</h2>
					<p className="text-xl text-white mt-4">
						{t(
							"Recruitment has never been this exciting! Join a dynamic work environment where passion and professionalism come together every day, paving the way for career growth."
						)}
					</p>
				</div>
			</div>

			<div className="container mx-auto flex flex-col justify-start align-start">
				<div className="py-12">
					<div className="">
						<h1 className="text-5xl text-bold text-left">{t("Featured posts")}</h1>
					</div>
				</div>
				<BlogEntriesSection posts={posts.filter((p) => p.featured)} showButton={false} />
			</div>
		</>
	);
};

export default Blog;
